import React from 'react'
import ReactModal from 'react-modal'

import './modal.css'

ReactModal.setAppElement('body')

const CLOSE_TIMEOUT_IN_MS = 200

class Modal extends React.Component {
  /*
  constructor(props) {
    super(props)
  }
  */

  getScrollbarWidth = () => {
    // Creating invisible container
    const outer = window.document.createElement('div')
    outer.style.visibility = 'hidden'
    outer.style.overflow = 'scroll' // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps
    window.document.body.appendChild(outer)

    // Creating inner element and placing it in the container
    const inner = window.document.createElement('div')
    outer.appendChild(inner)

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer)

    return scrollbarWidth
  }

  componentDidMount() {
    window.document.body.style.setProperty(
      '--scrollbar-width',
      this.getScrollbarWidth()
    )
  }

  render() {
    const {
      isOpen,
      contentLabel,
      onRequestClose,
      onAfterOpen,
      children,
    } = this.props

    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel={contentLabel}
        onRequestClose={onRequestClose}
        onAfterOpen={onAfterOpen}
        className={{
          base: 'modal',
          afterOpen: 'in',
          beforeClose: 'out',
        }}
        overlayClassName={{
          base: 'overlay',
          afterOpen: 'in',
          beforeClose: 'out',
        }}
        bodyOpenClassName="modal-body-open"
        htmlOpenClassName="modal-html-open"
        closeTimeoutMS={CLOSE_TIMEOUT_IN_MS}
        style={{
          overlay: {
            '--close-timeout': CLOSE_TIMEOUT_IN_MS,
          },
        }}
      >
        <div className="modal-content">{children}</div>
      </ReactModal>
    )
  }
}

export default Modal
