import React from 'react'
import Script from 'react-load-script'

class RainbowText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rainbowTextLibLoaded: false,
    }
  }

  rainbowTextLibLoaded = () => {
    this.setState({
      rainbowTextLibLoaded: true,
    })
  }

  render() {
    const { children } = this.props
    if (typeof children !== 'string') return null
    return (
      <>
        <Script
          url="/js/rainbow-text.js"
          attributes={{ type: 'module' }}
          onLoad={this.rainbowTextLibLoaded}
        />
        <rainbow-text>{children}</rainbow-text>
      </>
    )
  }
}

export default RainbowText
