import React from 'react'
import { navigate } from 'gatsby'
import jwt from 'jsonwebtoken'

import Layout from '../components/layout'
import Modal from '../components/modal'
import RainbowText from '../components/rainbow-text'
import MailBanner from '../components/mail-banner'

import '../utils/normalize.css'
import '../utils/css/screen.css'
import './contact.css'

const PIN_LENGTH = 6

const MESSAGE_TYPES = ['Make Offer', 'General Inquiry', 'Contact Admin']

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = ContactPage.getInitialState()

    this.pinInputsRef = []
    for (let i = 0; i < PIN_LENGTH; ++i) {
      this.pinInputsRef.push(React.createRef())
    }

    this.onAfterModalOpen = this.onAfterModalOpen.bind(this)
    this.handlePinInputChange = this.handlePinInputChange.bind(this)
    this.renderMessageTypeSelects = this.renderMessageTypeSelects.bind(this)
    this.renderPinInputs = this.renderPinInputs.bind(this)
  }

  static getInitialState() {
    return {
      authorName: null,
      showPinInputModal: false,
      formReadonly: false,
      nameInput: '',
      emailInput: '',
      messageTypeSelect: MESSAGE_TYPES[0],
      messageInput: '',
      pinInputs: new Array(PIN_LENGTH).fill(''),
    }
  }

  componentDidMount() {
    try {
      const {
        location: {
          state: { authorToken },
        },
      } = this.props
      this.setState({
        authorName: jwt.decode(atob(authorToken)).name || 'Anonymous',
      })
    } catch (e) {
      navigate('/404', { replace: true })
    }
  }

  onAfterModalOpen() {
    this.pinInputsRef[0].current.focus()
    this.pinInputsRef[0].current.select()
  }

  handlePinInputChange(e, index) {
    const input = e.target.value
    console.warn('input change', input, typeof input, input.length)
    console.warn(this.state.pinInputs)
    const focusSelectIfInputsRefExist = index => {
      if (
        typeof this.pinInputsRef[index] !== 'undefined' &&
        this.pinInputsRef[index].current instanceof HTMLElement
      ) {
        this.pinInputsRef[index].current.focus()
        this.pinInputsRef[index].current.select()
        return true
      }
      return false
    }
    const updateArrayByArray = (lArr, rArr, startIndex) => {
      if (lArr.length < rArr.length + startIndex) return lArr
      return lArr
        .slice(0, startIndex)
        .concat(rArr)
        .concat(lArr.slice(startIndex + rArr.length))
    }
    if (
      typeof input === 'string' &&
      input.length > 0 &&
      e.target.validity.valid
    ) {
      const maxAllowedInputs = input.substring(0, PIN_LENGTH - index).split('')
      this.setState({
        pinInputs: updateArrayByArray(
          this.state.pinInputs,
          maxAllowedInputs,
          index
        ),
      })

      const lastUpdatedInputsRefNextIndex = index + maxAllowedInputs.length

      focusSelectIfInputsRefExist(lastUpdatedInputsRefNextIndex) ||
        focusSelectIfInputsRefExist(lastUpdatedInputsRefNextIndex - 1)
    } else {
      console.warn('==clear input==', index)
      this.setState({
        pinInputs: updateArrayByArray(this.state.pinInputs, [''], index),
      })
      focusSelectIfInputsRefExist(index)
    }
  }

  renderMessageTypeSelects() {
    const prefix = 'message-type'
    return MESSAGE_TYPES.map((type, index) => {
      const id = `${prefix}-${type.toLowerCase().replace(/ /g, '-')}`
      return (
        <div className="col-4 col-12-small" key={`${prefix}-radio-${index}`}>
          <input
            type="radio"
            id={id}
            name={prefix}
            disabled={this.state.formReadonly}
            checked={this.state.messageTypeSelect === type}
            onChange={e => {
              this.setState({ messageTypeSelect: type })
            }}
          />
          <label htmlFor={id}>{type}</label>
        </div>
      )
    })
  }

  renderPinInputs() {
    const inputs = []
    const rows = []
    for (let i = 0; i < PIN_LENGTH; ++i) {
      inputs.push(
        <div className="col" key={`pin-input-${i}`}>
          <input
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            required
            ref={this.pinInputsRef[i]}
            maxLength={PIN_LENGTH}
            onChange={e => this.handlePinInputChange(e, i)}
            onInput={e => this.handlePinInputChange(e, i)}
            value={this.state.pinInputs[i]}
            onClick={e => {
              e.target.select()
            }}
          />
        </div>
      )
      if (i % 3 === 2) {
        rows.push(
          <div className="col-6" key={`pin-input-row-${rows.length}`}>
            <div className="row">{inputs.slice(i - 2, i + 1)}</div>
          </div>
        )
      }
    }
    return <div className="row pin-input-group">{rows}</div>
  }

  render() {
    return (
      <Layout>
        <Modal
          isOpen={this.state.showPinInputModal}
          contentLabel="PinInputModal"
          onAfterOpen={this.onAfterModalOpen}
        >
          <div className="col-12 pin-input-dialog-body">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <MailBanner />
            </div>
            <h4 className="col-12">Please provide the PIN you received</h4>
            {this.renderPinInputs()}
            {/* break */}
            <div className="col-12">
              <div className="row">
                <div className="col-8">
                  <button
                    onClick={e => {
                      console.warn(this.state)
                      this.setState({
                        formReadonly: false,
                        showPinInputModal: false,
                      })
                    }}
                    className="primary fit"
                  >
                    Submit
                  </button>
                </div>
                <div className="col-4">
                  <button
                    onClick={e => {
                      console.warn(this.state)
                      this.setState({
                        formReadonly: false,
                        showPinInputModal: false,
                      })
                    }}
                    className="fit"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <article className="post-content page-template no-image">
          <div
            className={
              !!this.state.authorName
                ? 'post-content-body'
                : 'post-content-body transparent'
            }
          >
            <h2>Let's get you connected</h2>
            <h3>
              <RainbowText>{this.state.authorName}</RainbowText>{' '}
              <small>will be thrilled to hear from you</small>
            </h3>
            <blockquote>
              To allow you contact the author of the post, we must verify your
              identity first.
            </blockquote>
            <blockquote>
              Your privacy is very important to us. We will never share your
              information with any third party.
              <br />
              We encrypt the information you provided with bleeding edge
              security measurements which comply with industrial standard and
              local law.
              <br />
              Please find our{' '}
              <a
                href="https://haocen.me/privacy_policy?site=Rummage"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy here
              </a>
              .
            </blockquote>
            {/* Break */}
            <form
              onSubmit={e => {
                console.warn(this.state)
                e.preventDefault()
                this.setState({
                  formReadonly: true,
                  showPinInputModal: true,
                })
              }}
              onReset={e => {
                e.preventDefault()
                this.setState(
                  Object.assign(ContactPage.getInitialState(), {
                    authorName: this.state.authorName,
                  })
                )
              }}
            >
              <div className="row gtr-uniform">
                <div className="col-6 col-12-xsmall">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    readOnly={this.state.formReadonly}
                    value={this.state.nameInput}
                    onChange={e => {
                      this.setState({ nameInput: e.target.value })
                    }}
                  />
                </div>
                <div className="col-6 col-12-xsmall">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    readOnly={this.state.formReadonly}
                    value={this.state.emailInput}
                    onChange={e => {
                      this.setState({ emailInput: e.target.value })
                    }}
                  />
                </div>
                {/* Break */}
                {/* TODO: Make this useful */}
                {/* this.renderMessageTypeSelects() */}
                {/* Break */}
                <div className="col-12">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Enter your message"
                    rows={6}
                    required
                    readOnly={this.state.formReadonly}
                    value={this.state.messageInput}
                    onChange={e => {
                      this.setState({ messageInput: e.target.value })
                    }}
                  />
                </div>
                {/* Break */}
                <div className="col-12">
                  <div className="row">
                    <div className="col-8">
                      <input
                        type="submit"
                        value="Send Message"
                        className="primary fit"
                        disabled={this.state.formReadonly}
                      />
                    </div>
                    <div className="col-4">
                      <input
                        type="reset"
                        value="Reset"
                        className="fit"
                        disabled={this.state.formReadonly}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </article>
      </Layout>
    )
  }
}

export default ContactPage
