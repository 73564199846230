import React from 'react'
import Script from 'react-load-script'

import './mail-banner.css'

const envelopColors = ['#9FFFFF', '#E99FFF', '#FF9F9F', '#CCFF9F']

const getRandomInt = max => Math.floor(Math.random() * Math.floor(max))

class MailBanner extends React.Component {
  constructor(props) {
    super(props)
    this.zdogPaintArea = React.createRef()
    this.zdogLibLoaded = this.zdogLibLoaded.bind(this)
    this.tweenMaxLibLoaded = this.tweenMaxLibLoaded.bind(this)
    this.zdogPaint = this.zdogPaint.bind(this)
    this.state = {
      zdogLibLoaded: false,
      tweenMaxLibLoaded: false,
      envelopColor: envelopColors[0],
    }
  }

  componentDidMount() {
    const color = envelopColors[getRandomInt(envelopColors.length)]
    this.setState({
      envelopColor: color,
    })
    if (this.state.zdogLibLoaded && this.state.tweenMaxLibLoaded) {
      this.zdogPaint()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.zdogLibLoaded === false ||
        prevState.tweenMaxLibLoaded === false) &&
      (this.state.zdogLibLoaded && this.state.tweenMaxLibLoaded)
    ) {
      this.zdogPaint()
    }
  }

  zdogLibLoaded() {
    this.setState({
      zdogLibLoaded: true,
    })
  }

  tweenMaxLibLoaded() {
    this.setState({
      tweenMaxLibLoaded: true,
    })
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max))
  }

  zdogPaint() {
    /* eslint-disable no-unused-vars */
    const Zdog = window.Zdog
    const TimelineMax = window.TimelineMax
    const Back = window.Back
    const Power1 = window.Power1

    const paintElem = this.zdogPaintArea.current
    const w = 160
    const h = 160
    const elemComputedStyle = window.getComputedStyle(paintElem)
    const minPaint = Math.min(
      parseInt(elemComputedStyle.width, 10),
      parseInt(elemComputedStyle.height, 10)
    )
    const zoom = Math.min(20, Math.floor(minPaint / w))
    paintElem.setAttribute('width', w * zoom)
    paintElem.setAttribute('height', h * zoom)

    const paint = new Zdog.Illustration({
      element: paintElem,
      dragRotate: false,
    })

    const TAU = Math.PI * 2 // easier to read constant

    // envelop body
    new Zdog.RoundedRect({
      addTo: paint,
      width: 120,
      height: 80,
      fill: true,
      stroke: 10,
      cornerRadius: 14,
      color: this.state.envelopColor,
    })

    // address & stamp
    const addressAndStampGroup = new Zdog.Group({
      addTo: paint,
      translate: { z: -10 },
    })

    new Zdog.Rect({
      addTo: addressAndStampGroup,
      stroke: 5,
      width: 25,
      height: 35,
      fill: false,
      color: '#88DDFF',
      translate: { x: -35, y: -15 },
    })

    new Zdog.Rect({
      addTo: addressAndStampGroup,
      stroke: 5,
      width: 10,
      height: 20,
      fill: true,
      color: '#88DDFF',
      translate: { x: -35, y: -15 },
    })

    new Zdog.Shape({
      addTo: addressAndStampGroup,
      stroke: 2,
      path: [
        { x: 0, y: 0 },
        {
          arc: [
            { x: 10, y: -10 }, // corner
            { x: 20, y: 0 }, // end point
          ],
        },
        { x: 20, y: 0 },
        {
          arc: [
            { x: 30, y: 10 }, // corner
            { x: 40, y: 0 }, // end point
          ],
        },
      ],
      closed: false,
      color: '#DDDDDD',
      translate: { x: -40, y: 0 },
    })

    new Zdog.Shape({
      addTo: addressAndStampGroup,
      stroke: 2,
      path: [
        { x: 0, y: 0 },
        {
          arc: [
            { x: 10, y: -10 }, // corner
            { x: 20, y: 0 }, // end point
          ],
        },
        { x: 20, y: 0 },
        {
          arc: [
            { x: 30, y: 10 }, // corner
            { x: 40, y: 0 }, // end point
          ],
        },
      ],
      closed: false,
      color: '#DDDDDD',
      translate: { x: -40, y: -5 },
    })

    new Zdog.Shape({
      addTo: addressAndStampGroup,
      stroke: 2,
      path: [
        { x: 0, y: 0 },
        {
          arc: [
            { x: 10, y: -10 }, // corner
            { x: 20, y: 0 }, // end point
          ],
        },
        { x: 20, y: 0 },
        {
          arc: [
            { x: 30, y: 10 }, // corner
            { x: 40, y: 0 }, // end point
          ],
        },
      ],
      closed: false,
      color: '#DDDDDD',
      translate: { x: -40, y: -10 },
    })

    new Zdog.Shape({
      addTo: addressAndStampGroup,
      stroke: 2,
      path: [
        { x: 0, y: 0 },
        {
          arc: [
            { x: 10, y: -10 }, // corner
            { x: 20, y: 0 }, // end point
          ],
        },
        { x: 20, y: 0 },
        {
          arc: [
            { x: 30, y: 10 }, // corner
            { x: 40, y: 0 }, // end point
          ],
        },
      ],
      closed: false,
      color: '#DDDDDD',
      translate: { x: -40, y: -15 },
    })

    new Zdog.Shape({
      addTo: addressAndStampGroup,
      stroke: 5,
      path: [{ x: 20, y: 0 }, { x: -20, y: 0 }],
      color: '#DDDDDD',
      translate: { x: 25, y: 10 },
    })

    new Zdog.Shape({
      addTo: addressAndStampGroup,
      stroke: 5,
      path: [{ x: 15, y: 0 }, { x: -15, y: 0 }],
      color: '#DDDDDD',
      translate: { x: 30, y: 20 },
    })

    new Zdog.Shape({
      addTo: addressAndStampGroup,
      stroke: 5,
      path: [{ x: 10, y: 0 }, { x: -10, y: 0 }],
      color: '#DDDDDD',
      translate: { x: 35, y: 30 },
    })

    // shape heart
    const heartPath = (() => {
      let path = []
      const radius = 1.3
      for (let i = 0; i < 7; i += 0.1) {
        let point = {
          x: radius * 16 * Math.pow(Math.sin(i), 3) * radius,
          y:
            -radius *
            (13 * Math.cos(i) * radius -
              5 * Math.cos(2 * i) -
              2 * Math.cos(3 * i) -
              Math.cos(4 * i)),
        }
        path.push(point)
      }
      return path
    })()

    new Zdog.Shape({
      addTo: addressAndStampGroup,
      path: heartPath,
      closed: false,
      lineWidth: 2,
      color: '#fe6e7b',
      fill: true,
      scale: 0.5,
      translate: {
        x: 30,
        y: -15,
      },
    })

    // paperfold
    const paperFoldGroup = new Zdog.Group({
      addTo: paint,
      translate: { z: 5 },
    })

    const topFoldPath = [
      {
        x: -50,
        y: -35,
      },
      {
        x: 0,
        y: 8,
      },
      {
        x: 50,
        y: -35,
      },
    ]

    new Zdog.Shape({
      addTo: paperFoldGroup,
      path: topFoldPath,
      closed: false,
      stroke: 5,
      color: '#DDDDDD',
      fill: false,
    })

    const bottomLeftFoldPath = [
      {
        x: -50,
        y: 35,
      },
      {
        x: -12,
        y: -2,
      },
    ]

    new Zdog.Shape({
      addTo: paperFoldGroup,
      path: bottomLeftFoldPath,
      closed: false,
      stroke: 5,
      color: '#DDDDDD',
      fill: false,
    })

    const bottomRightFoldPath = [
      {
        x: 50,
        y: 35,
      },
      {
        x: 12,
        y: -2,
      },
    ]

    new Zdog.Shape({
      addTo: paperFoldGroup,
      path: bottomRightFoldPath,
      closed: false,
      stroke: 5,
      color: '#DDDDDD',
      fill: false,
    })

    // star shape
    const starPath = (() => {
      let path = []
      const starRadiusA = 25
      const starRadiusB = 15
      for (let i = 0; i < 10; i++) {
        let radius = i % 2 ? starRadiusA : starRadiusB
        let angle = (TAU * i) / 10 + TAU / 4
        let point = {
          x: Math.cos(angle) * radius,
          y: Math.sin(angle) * radius,
        }
        path.push(point)
      }
      return path
    })()

    new Zdog.Shape({
      addTo: paperFoldGroup,
      translate: { y: -20 },
      path: starPath,
      lineWidth: 2,
      color: '#EEE000',
      fill: true,
      scale: 0.5,
    })

    // render & animate
    const deg = Math.PI / 180

    const moveLike = new TimelineMax()
    moveLike.to({ my: 0 }, 5, {
      my: -20,
      onUpdate: function() {
        paint.translate.y = this.target.my
        paint.updateRenderGraph()
      },
      ease: Back.easeInOut.config(1.7),
      repeat: -1,
      yoyo: true,
    })

    const rotateLike = new TimelineMax()
    rotateLike.to({ ry: 0 }, 3, {
      ry: 360,
      onUpdate: function() {
        paint.rotate.y = this.target.ry * deg
        paint.updateRenderGraph()
      },
      ease: Power1.easeInOut,
      repeat: -1,
      yoyo: true,
    })
    /* eslint-enable no-unused-vars */
  }

  render() {
    return (
      <>
        <Script
          url="https://unpkg.com/zdog@1/dist/zdog.dist.min.js"
          onLoad={this.zdogLibLoaded}
        />
        <Script
          url="https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/TweenMax.min.js"
          onLoad={this.tweenMaxLibLoaded}
        />
        <svg className="mail-banner" ref={this.zdogPaintArea}></svg>
      </>
    )
  }
}

export default MailBanner
